import React from 'react';
import PropTypes from 'prop-types';

import Typo from '@dolstaff/shared/es/Typo';
import cl from 'classnames';
import { Link, graphql } from 'gatsby';
import * as smoothscroll from 'smoothscroll-polyfill';
import styled from 'styled-components';

import { OnlineHeaderStep } from 'shared/OnlineHeaderStep';

import Icon from '../../components/Icon';
import Tooltip from '../../components/Tooltip';
import { Container, Section as SectonOrg } from '../../shared/Blocks';
import Image from '../../shared/Image';
import MainLayout from '../../shared/MainLayout';
import {
  BigSection as BigSectionOrg,
  ImagePanel
} from '../../shared/Marketing/Shared';
import MySEO from '../../shared/MySEO';
import VideoThumbnail from '../../shared/VideoThumbnailInline';
import { isBrowser } from '../../utils';
import useWindowSize from '../../utils/hooks/useWindowSize';
import HeaderSchedulePage from '../../shared/Header/HeaderSchedulePage';

if (isBrowser()) {
  const windowGlobal = typeof window !== 'undefined' && window;
  // eslint-disable-next-line no-underscore-dangle
  windowGlobal.__forceSmoothScrollPolyfill__ = true;
}
if (isBrowser()) {
  smoothscroll.polyfill();
}

const BigSection = styled(BigSectionOrg)`
  margin-top: 2em;
  margin-bottom: 2em;
  transform: translateX(0%);
  transition: 0.4s;
`;

const Section = styled(SectonOrg)`
  background-color: #fbfbfb;

  @media screen and (min-width: 1024px) {
    min-height: 700px;
    display: flex;
    justify-content: stretch;
  }
`;

const VideoContentWrp = styled.div`
  display: none;

  .contentTitle {
    color: #242c34;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .contentBody {
    color: #2d3742;
    font-size: 18px;
  }
  img {
    object-fit: contain !important;
  }
  &.active {
    display: block;
    flex: 1;
    max-height: 450px;
    > div {
      height: 100%;
      > div {
        height: 100%;
        .graphcms-image-wrapper,
        .graphcms-image-outer-wrapper {
          height: 100%;
        }
        .graphcms-image-wrapper {
          > div {
            padding: 0 !important;
            @media screen and (max-width: 767px) {
              padding-bottom: 350px !important;
            }
            @media screen and (max-width: 480px) {
              padding-bottom: 200px !important;
            }
          }
        }
      }
    }
    .videoPlayer {
      height: 100%;
    }
  }
`;

const BulletTitle = styled.div`
  font-size: 24px;
  margin-bottom: 1em;
  line-height: 1.2;
  color: #242c34;
  font-weight: bold;
  b {
    color: #d14242;
  }

  .bullet {
    font-size: 1.4em;
    font-weight: 600;
    flex: 50px 0 0;
    width: 50px;
    margin-top: -0.3em;
    margin-bottom: 0.4em;
    line-height: 1;
  }
  .content {
    font-size: 1em;
    font-weight: 600;
  }

  @media screen and (min-width: 1024px) {
    /* font-size: 32px; */
    position: relative;
    display: flex;

    .bullet {
      width: 80px;
      text-align: right;
      position: absolute;
      padding-right: 24px;
    }
    .content {
      padding-left: 80px;
    }
  }
`;

const TooltipShowOnce = styled.div`
  position: absolute;
  z-index: 2;
  top: calc(50% - 38px + 4px);
  left: -135px;
  background-color: #2d3742;
  border-radius: 10px;
  border: 1px solid #2d3742;
  color: white;
  padding: 1em;
  width: 154px;
  text-align: center;
  font-size: 13px;
  display: none;
  &.open {
    display: block;
  }
  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent transparent #2d3742;
  }
`;

const imgStep2 = {
  handle: 'RaPAwWkQ6edbbKGQxdZw',
  width: 2028,
  height: 1952
};
const ImgPlayIcon = styled(Icon).attrs({ type: 'PlayCircleOutlineIcon' })`
  font-size: 48px;
  margin-right: 16px;
  &.active {
    svg path {
      fill: #d14242;
    }
  }
  &.white {
    svg path {
      fill: #fbfbfb;
    }
  }
`;

const ImgPlayIconFill = styled(Icon).attrs({ type: 'Play' })`
  font-size: 12px;
  min-width: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  svg path {
    fill: white;
  }
`;

const Main = styled.main`
  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0.2rem rgba(209, 66, 66, 0.2);
    }
    100% {
      box-shadow: 0 0 0 1.4rem rgba(209, 66, 66, 0);
    }
  }
  .ripple {
    position: absolute;
    background-color: #d14242;
    left: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    z-index: -1;
    animation: ripple 1s linear infinite;
  }

  .ripple::after {
    animation: ripple 1s linear infinite 1s;
  }
  .vh100 {
    height: calc(100vh - 154px);
    min-height: auto;
    overflow: hidden;
    padding-top: 0;
    @media screen and (max-width: 1023px) {
      overflow-y: auto;
      padding-top: 0;
    }
  }
  .vh100 section {
    margin: 0;
  }
  .vh100.noFooter {
    height: calc(100vh - 72px);
    overflow: hidden;
  }
  .FixedFooter {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px -3px 30px rgba(163, 163, 164, 0.2);
    position: fixed;
    bottom: 0;
    padding: 0 20px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    .wrapper-footer {
      max-width: 1140px;
      width: 1140px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
    }
  }
  /* .FixedFooter::before {
    content: '';
    position: fixed;
    width: 100%;
    height: 80px;
    left: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 0px -3px 30px rgba(163, 163, 164, 0.2);
    z-index: -1;
  } */
  .btnBack,
  .btnNext {
    box-sizing: border-box;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 160px;
    cursor: pointer;
  }
  .btnBack {
    border: 2px solid #ebecf0;
    background: #fff;
    color: #2d3742;
  }
  .btnNext {
    background: #d14242;
    color: #fff;
    &.disabled {
      background: #dfe1e6;
      color: #ffffff;
      pointer-events: none;
    }
  }
  .TutorialVideo h6 {
    color: #242c34;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 16px;
  }
  .TutorialVideo {
    margin-top: 40px;
  }
  .LectureThinking {
    @media screen and (max-width: 1023px) {
      max-height: 400px;
    }
    ul {
      padding-left: 18px;
      margin: 0;
      li {
        margin-bottom: 18px;
        color: #242c34;
        font-size: 16px;
        b {
          color: #d14242;
        }
      }
    }
    .panelImage {
      img {
        object-fit: contain !important;
        width: auto !important;
        height: auto !important;
        left: 50% !important;
        transform: translateX(-50%);
      }
    }
  }
  .video-detail-section-desktop {
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
  .VideoMain {
    position: absolute;
    top: 0;
    left: calc(100vw - 64px - 489px);
    max-width: 489px;
    /* max-width: calc(100% - 92px); */
    overflow: visible;
    width: 100%;
    height: 100%;
    transition: 0.4s;
    background: #fbfbfb;
    border: 1px solid #fbfbfb;
    box-sizing: border-box;
    box-shadow: 0px 16px 32px rgba(45, 55, 66, 0.1);
    z-index: 2;
    @media screen and (max-width: 1023px) {
      position: relative;
      max-width: 100%;
      transform: translateY(-15%);
      left: 0;
      overflow: visible;
      background: transparent;
      box-shadow: none;
      display: flex;
    }
    @media screen and (max-width: 767px) {
      display: block;
    }
    .VideoList {
      padding: 32px 0px;
      padding-bottom: 0;
      color: #242c34;
      width: 100%;
      font-size: 20px;
      display: inline-block;
      @media screen and (max-width: 1023px) {
        max-width: 350px;
      }
      @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        max-width: 100%;
      }
      h3 {
        display: inline-block;
        margin: 0;
        margin-right: 10px;
        font-weight: normal;
      }
      ul {
        display: inline-block;
        padding-left: 0;
        list-style: none;
        margin: 0;
        margin-bottom: 32px;
        li {
          display: inline-block;
          &::after {
            content: '/';
            display: inline-block;
            margin: 0 5px;
          }
          &:last-child {
            &::after {
              content: none;
            }
          }
          &.active {
            a {
              color: #d14242;
              border-bottom: 2px solid;
            }
          }
          a {
            color: #242c34;
            &:hover {
              color: #d14242;
            }
          }
        }
      }
      .VideoListHeader {
        padding: 0 40px;
      }
      .wrapScroll {
        overflow-x: visible;
        max-height: calc(100vh - 249px);
        @media screen and (max-width: 1023px) {
          max-height: 700px;
        }
      }
      .VideoBlock {
        padding: 12px 40px;
        position: relative;
        cursor: pointer;
        .popup-content {
          display: none;
        }
        a {
          position: absolute;
          top: 0;
          left: 0;
          background: transparent;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
        &.active {
          background: #ebecf0;
          .VideoListInner {
            color: #d14242;
          }
        }
        .duration {
          color: #a5adba;
          font-size: 14px;
          line-height: 12px;
        }
      }
      .VideoListInner {
        line-height: normal;
        position: relative;
        display: flex;
        align-items: center;
        h6 {
          font-size: 16px;
          color: #242c34;
          margin: 0;
          margin-bottom: 4px;
          line-height: 24px;
          font-weight: normal;
        }
        span {
          color: #a5adba;
          font-size: 14px;
        }
        .graphcms-image-outer-wrapper {
          position: absolute !important;
          width: 32px;
          left: 0;
        }
      }
    }
  }
  /* .bodyStep2 {
    padding-left: 60px;
    @media screen and (max-width: 1023px) {
      padding-left: 0;
    }
  } */
  .video-title {
    font-size: 16px;
  }
  .RespHeader {
    position: relative;
    padding-left: 56px;
    margin-bottom: 32px;
    display: none;
    @media screen and (max-width: 1024px) {
      display: block;
    }
    .cirle {
      width: 42px;
      height: 42px;
      position: absolute;
      left: 0;
      .label {
        font-weight: bold;
        font-size: 12px;
        position: absolute;
        line-height: 38px;
        display: block;
        width: 100%;
        text-align: center;
        letter-spacing: 1.5px;
        border: 2px solid #a5adba;
        border-radius: 50%;
        b {
          color: #d14242;
        }
      }
      .circleProgress {
        position: relative;
        width: 42px;
        height: 42px;
        .circleCommon {
          width: 50%;
          height: 100%;
          overflow: hidden;
          position: absolute;
          top: 0;
          z-index: 1;
          .progressPercent {
            width: 100%;
            height: 100%;
            background: none;
            border-width: 2px;
            border-style: solid;
            position: absolute;
            top: 0;
            border-color: #d14242;
          }
          &.Left {
            left: 0;
            display: none;
            .progressPercent {
              left: 100%;
              border-top-right-radius: 80px;
              border-bottom-right-radius: 80px;
              border-left: 0;
              -webkit-transform-origin: center left;
              transform-origin: center left;
            }
          }
          &.Right {
            right: 0;
            .progressPercent {
              left: -100%;
              border-top-left-radius: 80px;
              border-bottom-left-radius: 80px;
              border-right: 0;
              -webkit-transform-origin: center right;
              transform-origin: center right;
              animation: loading-1 1.8s linear forwards;
              transform: rotate(150deg);
            }
          }
        }
      }
    }
    h6 {
      color: #242c34;
      font-size: 16px;
      margin: 0;
    }
    span {
      color: #a5adba;
      font-size: 12px;
      display: block;
      margin-top: 0;
    }
  }

  .btnVideoBack {
    position: absolute;
    left: 0px;
    top: 0;
    width: 92px;
    height: 100%;
    background: #f4f5f7;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
    display: none;
    cursor: pointer;
    > div {
      transform: rotate(-90deg);
      > a {
        white-space: nowrap;
        color: #7a869a;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    &:hover {
      a {
        color: #d14242;
      }
    }
  }

  .VideoDetail {
    transition: 0.4s;
    display: inline-block;
    vertical-align: top;
    background: #fff;
    width: 100%;
    height: 100%;
    padding: 31px 52px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1023px) {
      background: transparent;
    }
    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
    }
    /* .graphcms-image-outer-wrapper {
      max-width: 800px;
    } */
    h3 {
      color: #242c34;
      font-size: 24px;
      margin-bottom: 36px;
      margin-top: 0;
    }
    p {
      margin-top: 24px;
      a {
        color: #d14242;
      }
    }
  }

  @media screen and (min-width: 1023px) {
    .video-detail-section-desktop {
      position: absolute;
      transition: 0.4s;
      left: calc(100vw - 64px);
      width: 64px;
      top: 0;
      bottom: 0;
      background-color: #ffffff;
      z-index: 2;
    }
  }

  .video-detail-section-mobile {
    display: none;
    @media screen and (max-width: 1023px) {
      flex: 1;
      display: block;
      overflow: auto;
      padding-bottom: 90px;
    }
  }

  &.show-video-detail {
    @media screen and (min-width: 1023px) {
      .VideoMain {
        left: 92px;
      }
      ${BigSection} {
        z-index: 0;
        transform: translateX(-150%);
      }
      .video-detail-section-desktop {
        right: 0;
        left: 581px;
        width: auto;
        overflow: auto;
        padding-bottom: 20px;
      }
      .btnVideoBack {
        display: flex;
      }
    }
  }
`;

const IndexPage = props => {
  let videoRef = null;
  const { atwPageOnlineCourseVideos } = props.data.gcms;
  const [skillIndex, setSkillIndex] = React.useState(0);
  const [isShowRipple, showRipple] = React.useState(true);
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const currentVideo = atwPageOnlineCourseVideos[skillIndex].video[activeIndex];
  const w = useWindowSize();
  const isNotBigScreen = w.innerWidth <= 1023;
  const ALL_SKILLS = atwPageOnlineCourseVideos.map((item, i) => {
    if (i === atwPageOnlineCourseVideos.length - 1) {
      return item.skill;
    }
    return item.skill[0];
  });

  React.useEffect(() => {
    if (isNotBigScreen && activeIndex === -1) {
      setActiveIndex(0);
    }
    if (activeIndex >= 0) {
      showRipple(false);
    }
  }, [activeIndex, isNotBigScreen]);

  return (
    <MainLayout
      showFooterRegister={false}
      noMenu
      noFooter
      hideSideCTA
      noHeader
      renderCustomHeader={() => (
        <HeaderSchedulePage hideMobileBottomNav noMenu />
      )}
    >
      <MySEO title="Bước 2: Khóa học online, trải nghiệm như offline" />
      <Main className={cl({ 'show-video-detail': activeIndex >= 0 })}>
        <OnlineHeaderStep activeStep={1} />
        <Section selfish className="vh100">
          <Container>
            <BigSection>
              <Container>
                <ImagePanel>
                  <div
                    className="panelBody bodyStep2"
                    style={{ flex: '50px 1 1' }}
                  >
                    <div className="RespHeader">
                      <div className="cirle">
                        <div className="label">
                          <b>2</b>/5
                        </div>
                        <div className="circleProgress">
                          <div className="circleCommon Left ">
                            <span className="progressPercent" />
                          </div>
                          <div className="circleCommon Right ">
                            <span className="progressPercent" />
                          </div>
                        </div>
                      </div>
                      <h6>Bắt đầu học</h6>
                      <span>Next: Giải đáp</span>
                    </div>
                    <BulletTitle>
                      {/* <div className="bullet">01</div> */}
                      <div>BƯỚC 02: BẮT ĐẦU HỌC</div>
                    </BulletTitle>
                    <div className="LectureThinking">
                      <h3>Tư duy bài giảng</h3>
                      <ul>
                        <li>
                          DOL biết rằng khả năng tập trung của bạn học viên
                          thường <b>không cao</b>, đặc biệt là học online. khiến
                          các bạn <b>rất dễ bị sao lãng</b>, không theo kịp bài
                          và <b>tụt lại phía sau.</b>
                        </li>
                        <li>
                          Nên DOL <b>chia nhỏ bài giảng</b> thành từng phần, và
                          các phần này <b>liên kết chặt chẽ</b> với nhau, được
                          trình bày theo nhiều cách khác nhau để luôn đảm bảo sự{' '}
                          <b>mới mẻ</b>, thu hút sự tập trung <b>tối đa</b> của
                          người học.
                        </li>
                      </ul>
                      <div className="panelImage">
                        <Image image={imgStep2} maxWidth={500} />
                      </div>
                    </div>
                  </div>
                  <div className="panelImage" style={{ flex: '50px 1 1' }} />
                </ImagePanel>
              </Container>
            </BigSection>
          </Container>
          <div
            role="button"
            tabIndex={0}
            className="btnVideoBack"
            onClick={() => setActiveIndex(-1)}
          >
            <div>
              <a>
                <span>tư duy bài giảng</span>
              </a>
            </div>
          </div>
          <div className="VideoMain">
            <div className="VideoList">
              <div className="VideoListHeader">
                <h3>Video bài giảng</h3>
                <ul>
                  {ALL_SKILLS.map((skill, index) => {
                    return (
                      <li
                        key={skill}
                        className={cl({ active: skillIndex === index })}
                        onClick={() => {
                          setActiveIndex(0);
                          setSkillIndex(index);
                        }}
                      >
                        <a href="#">{skill}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="wrapScroll">
                {atwPageOnlineCourseVideos[skillIndex].video.map(
                  (video, index) => {
                    const { source, title, duration } = video;
                    return (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          setActiveIndex(index);
                          if (videoRef) {
                            videoRef.scrollIntoView({
                              behavior: 'smooth'
                            });
                          }
                        }}
                        key={source}
                        className={cl('VideoBlock', {
                          active: activeIndex === index
                        })}
                      >
                        <Tooltip
                          // content={title}
                          trigger={() => (
                            <div>
                              <a href="#" />
                              <div className="VideoListInner">
                                {index === 0 && isShowRipple ? (
                                  <div className="ripple" />
                                ) : null}
                                {index === 0 && isShowRipple ? (
                                  <ImgPlayIconFill />
                                ) : (
                                  <ImgPlayIcon
                                    className={cl({
                                      active: activeIndex === index
                                    })}
                                  />
                                )}
                                <div>
                                  <Typo
                                    className="video-title"
                                    htmlContent={title}
                                    truncate={60}
                                    marginBottom="4px"
                                  />
                                  <div className="duration">{duration}</div>
                                </div>
                              </div>
                            </div>
                          )}
                        />
                        <TooltipShowOnce
                          className={cl({ open: index === 0 && isShowRipple })}
                        >
                          Trải nghiệm thử video bài giảng
                        </TooltipShowOnce>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <div className="video-detail-section-mobile">
              {activeIndex >= 0 ? (
                <VideoDetail
                  videoRef={c => {
                    videoRef = c;
                  }}
                  cover={currentVideo.cover.wide}
                  video={currentVideo.file}
                  title={currentVideo.title}
                  description={currentVideo.description.text}
                />
              ) : null}
            </div>
          </div>
        </Section>
        <div className="video-detail-section-desktop">
          {activeIndex >= 0 ? (
            <VideoDetail
              cover={currentVideo.cover.wide}
              video={currentVideo.file}
              title={currentVideo.title}
              description={currentVideo.description.text}
            />
          ) : null}
        </div>
        <div className="FixedFooter">
          <div className="wrapper-footer">
            <Link to="/online/step1" className="btn btnBack">
              Quay lại
            </Link>
            <Link
              to="/online/step3"
              className={cl('btn', 'btnNext', {
                disabled: isShowRipple
              })}
            >
              Tiếp
            </Link>
          </div>
        </div>
      </Main>
    </MainLayout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    gcms: PropTypes.any
  })
};

const VideoDetail = props => {
  const { title, description, video, cover, videoRef } = props;

  // const { useID } = "react-id-generator";

  // const [isSeeMore, setSeeMore] = React.useState(description.length > 200);
  const finalDescription = description;
  // if (isSeeMore && description.length > 200) {
  //   finalDescription = `${description.substr(0, 200)}...`;
  // }
  return (
    <div className="VideoDetail" ref={videoRef}>
      <h3>{title}</h3>
      <VideoContentWrp className={cl({ active: true })}>
        <VideoThumbnail poster={cover} maxWidth={864} video={video} />
      </VideoContentWrp>
      <p>
        {finalDescription}&nbsp;
        {/* {isSeeMore ? (
          <a onClick={() => setSeeMore(false)} href="#">
            See more
          </a>
        ) : null} */}
      </p>
    </div>
  );
};

VideoDetail.propTypes = {
  cover: PropTypes.any,
  description: PropTypes.any,
  title: PropTypes.any,
  video: PropTypes.any,
  videoRef: PropTypes.any
};

export default IndexPage;

export const pageQuery = graphql`
  query fetchStep2Video {
    gcms {
      atwPageOnlineCourseVideos(stage: PUBLISHED, orderBy: sortIndex_ASC) {
        stage
        id
        video {
          duration
          cover {
            id
            wide {
              id
              handle
              width
              height
            }
          }
          file {
            handle
            url
            width
            height
          }
          title
          description {
            text
          }
          id
        }
        skill
      }
    }
  }
`;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import getAssetLink from '../../utils/getAssetLink';
import Image from '../Image';

const Main = styled.div`
  position: relative;
  margin-bottom: 1em;
  cursor: pointer;
  background-color: whitesmoke;
  border-radius: 1em;
  overflow: hidden;

  .backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(36, 44, 52, 0.4);
  }

  .playButton {
    position: absolute;
    width: 64px;
    height: 64px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    z-index: 2;
    overflow: hidden;
  }

  .playButtonContentWrp {
    width: 66px;
    height: 66px;
    margin: -1px;
  }
`;

const playButton = {
  handle: 'SLQOzrORluusj8VkO7ag',
  width: 384,
  height: 382
};

const VideoThumbnailInline = props => {
  const { poster, video, maxWidth = 800, videoProps, ...mainProps } = props;
  const [showModal, setShowModal] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    setShowModal(false);
  }, [video]);

  const triggerVideo = useCallback(() => {
    videoRef.current.play();
    setShowModal(true);
  }, [videoRef]);
  return (
    <div>
      {!showModal && (
        <Main {...mainProps} onClick={triggerVideo}>
          <Image image={poster} maxWidth={maxWidth} />
          <div className="backdrop" />
          <div className="playButton">
            <div className="playButtonContentWrp">
              <Image image={playButton} maxWidth={64} />
            </div>
          </div>
        </Main>
      )}
      <video
        ref={videoRef}
        className="videoPlayer"
        controls
        preload="meta"
        id="video-auto-play-modal"
        style={{ width: '100%', display: !showModal && 'none' }}
        src={getAssetLink({ item: video })}
        type="video/mp4"
        {...videoProps}
      >
        <track kind="captions" />
      </video>
    </div>
  );
};

VideoThumbnailInline.propTypes = {
  video: PropTypes.shape({}),
  poster: PropTypes.shape({}),
  maxWidth: PropTypes.number,
  videoProps: PropTypes.shape({})
};

export default React.memo(VideoThumbnailInline);

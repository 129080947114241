import Popover2 from '@dolstaff/shared/es/Popover2';
import PropTypes from 'prop-types';
import React from 'react';

const Tooltip = props => {
  const { position, content, trigger } = props;
  return (
    <Popover2
      position={position || 'right center'}
      trigger={trigger}
      contentStyle={{
        backgroundColor: 'rgba(107, 107, 107, 0.8)',
        borderRadius: '10px',
        // border: '1px solid rgba(107, 107, 107, 0.8)',
        width: '150px',
        color: 'rgb(250, 250, 250)',
        padding: '1em'
      }}
    >
      {() => <div>{content}</div>}
    </Popover2>
  );
};
Tooltip.propTypes = {
  position: PropTypes.string,
  content: PropTypes.string.isRequired,
  trigger: PropTypes.func.isRequired
};
Tooltip.defaultProps = {
  position: 'bottom center'
};

export default Tooltip;
